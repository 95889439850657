@import "assets/css/variables.scss";

:root {
    --height-customer-exists: 30px;
}

html {
    overflow-X: hidden;
}

.appContainer {
    flex: 1;
    padding-top: var(--margin);
}

.container {
    @media #{$mq-upto-l} {
        max-width: 9999px !important;
        padding-left: 30px !important;
        padding-right: 30px !important;
    }
    @media #{$mq-upto-s} {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
}

.toast-content-encompass {
    margin: -14px -28px -14px -14px;
    padding: 14px 28px 14px 14px;
    text-align: left;
}